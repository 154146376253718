<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-14 09:29:36
 * @Description: 消息通知
-->
<style lang="scss" scoped>
.page-notify {
  @include innerPage;
  @include pageHead(-42px);
  @include defalutTable(calc(100% - 162px), 10px);
  @include pageFoot;

  .search-form {
    margin-left: auto;
    margin-right: 20px;
  }
}
</style>

<template>
  <section class="page-notify">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
      <div class="head-wrapper column">
        <breadcrumb />
        <div class="wrap-plate">
          <div class="search-form">
            <el-form inline :model="searchForm">
              <el-form-item>
                <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="输入标题关键字" clearable>
                  <span slot="prefix" class="iconfont">&#xe61c;</span>
                </el-input>
              </el-form-item>
            </el-form>
            <el-button type="custom_primary" size="medium" @click="searchData">查询</el-button>
          </div>
          <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
            返回
          </el-button>
        </div>
      </div>
    </div>
    <div class="table-box">
      <div class="table-inner" ref="tableInner">
        <el-table :data="tableData" height="100%">
          <el-table-column align="center" prop="pltea_mes_title" label="标题" min-width="28.8%" />
          <!-- <el-table-column align="center" prop="pltea_mes_content" label="内容 " min-width="23.3%" /> -->
          <el-table-column align="center" label="时间" min-width="22%">
            <template slot-scope="scope">
              {{ scope.row.create_time * 1000 | formatTime }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="状态" min-width="8%">
            <template slot-scope="scope">
              <p class="table-status"
                :class="{ danger: scope.row.pltea_mes_status == 20, failed: scope.row.pltea_mes_status == 10 }">
                {{ scope.row.pltea_mes_status == 10 && '已读' || '未读' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" min-width="17.9%">
            <template slot-scope="scope">
              <el-button type="custom_success" size="mini" plain
                @click="$router.push({ name: 'HOME_NOTIFYDETAIL', params: { id: scope.row.pltea_mes_id } })">
                查看
              </el-button>
              <el-button type="custom_danger" size="mini" plain @click="delMsg(scope.row.pltea_mes_id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table-foot custom-foot">
        <div class="button-group">
          <el-button type="custom_success" @click="readAll" size="medium">
            <span class="iconfont">&#xe61b;</span>
            <span>全部已读</span>
          </el-button>
        </div>
        <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage" />
      </div>
    </div>
  </section>
</template>

<script>
import { $schoolMsgList2, $schoolMsgRead, $delSchoolMsg } from "@api/home"
import customPagination from "@comp/customPagination"
export default {
  name: "home_notify",
  components: { customPagination },
  data() {
    return {
      tableData: [],
      tablePage: {
        pageIndex: 1,
        total: 0
      },
      searchForm: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    /** 获取消息列表 */
    async getData() {
      let params = {
        ...this.searchForm,
        pageindex: this.tablePage.pageIndex
      }
      let { data: res } = await $schoolMsgList2(params);
      this.tablePage.total = res.allcount;
      this.tableData = res.data;
      this.$forceUpdate();
    },
    /** 翻页 */
    flippingPage(val) {
      this.tablePage.pageIndex = val;
      this.getData();
    },
    /** 搜索 */
    searchData() {
      this.tableData = [];
      this.tablePage.pageIndex = 1;
      this.getData();
    },
    /** 全部标记已读 */
    readAll() {
      this.$msgbox({
        title: '提示',
        message: '确认将所有消息通知标记为已读？',
        type: 'info',
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: 'el-button--custom_info',
        confirmButtonClass: 'el-button--custom_primary',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        const res = await $schoolMsgRead();
        if (res) {
          this.$message({
            type: 'success',
            duration: 1500,
            message: '全部标记成功'
          });
          this.getData();
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 1500,
          message: '操作已取消'
        })
      });
    },
    /**
     * 删除消息
     * @param {number} id 消息id
     */
    delMsg(id) {
      this.$msgbox({
        title: '删除',
        message: '确认删除此条消息通知？',
        type: 'warning',
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: 'el-button--custom_info',
        confirmButtonClass: 'el-button--custom_primary',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        const res = await $delSchoolMsg(id);
        if (res) {
          this.$message({
            type: 'success',
            duration: 1500,
            message: '消息已删除！'
          });
          this.tablePage.total = this.tablePage.total - 1 < 0 && 0 || this.tablePage.total - 1;
          if (this.tableData.length == 1 && this.tablePage.pageIndex != 1) {
            this.tablePage.pageIndex = this.tablePage.pageIndex - 1;
          }
          this.getData();
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 1500,
          message: '操作已取消'
        })
      });
    }
  },
};
</script>
